$graph-overlay-top-margin: 15px;
$graph-overlay-side-margin: 15px;
@import "ag-grid-community/styles/ag-grid.css";
@import "ag-grid-community/styles/ag-theme-alpine.css";
@import "leaflet/dist/leaflet.css";
@import "bootstrap/dist/css/bootstrap.min.css";

@keyframes spin {
  0.0% {
    transform: rotate(0deg);
    transform: rotate(0deg);
    transform: rotate(0deg);
    transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100.0% {
    transform: rotate(360deg);
    transform: rotate(360deg);
    transform: rotate(360deg);
    transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes spin {
  0.0% {
    transform: rotate(0deg);
    transform: rotate(0deg);
    transform: rotate(0deg);
    transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100.0% {
    transform: rotate(360deg);
    transform: rotate(360deg);
    transform: rotate(360deg);
    transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes spin {
  0.0% {
    transform: rotate(0deg);
    transform: rotate(0deg);
    transform: rotate(0deg);
    transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100.0% {
    transform: rotate(360deg);
    transform: rotate(360deg);
    transform: rotate(360deg);
    transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes spin {
  0.0% {
    transform: rotate(0deg);
    transform: rotate(0deg);
    transform: rotate(0deg);
    transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100.0% {
    transform: rotate(360deg);
    transform: rotate(360deg);
    transform: rotate(360deg);
    transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes spin {
  0.0% {
    transform: rotate(0deg);
    transform: rotate(0deg);
    transform: rotate(0deg);
    transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100.0% {
    transform: rotate(360deg);
    transform: rotate(360deg);
    transform: rotate(360deg);
    transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

html {
  height: 100%;
  margin: 0;
  overflow-y: auto;
  padding: 0;
  width: 100%;
}

body {
  font-family: "Trebuchet MS", "Arial", sans-serif;
  font-size: 17pt;
  height: 100%;
  margin: 0;
  min-height: 100%;
  padding: 0;
  width: 100%;
}

.header {
  background-color: #5c497e;
  color: #fff;
  display: flex;
  flex-direction: row;
  height: 50px;
  margin: 0;
  padding: 0 0.5em;
}

.header img {
  display: inline-block;
}

.nav-middle {
  flex: 1;
  text-align: center;
}

#nav-links {
  align-items: stretch;
  display: inline-flex;
  height: 50px;
  list-style: none;
  margin: 0;
  padding: 11px 0 0;
}

#nav-links a {
  color: #fff;
  font-size: 20px;
  font-weight: normal;
}

#nav-links > li {
  display: inline-block;
  padding: 0 25px 2px;
  text-align: center;
}

#nav-links > li > a:hover {
  color: #a9a9a9;
}

.nav-left {
  width: 250px;
}

#courseography-header {
  padding-top: 10px;
  width: 200px;
}

.nav-right {
  text-align: right;
  width: 250px;
}

#nav-export {
  background: transparent;
  border: 0;
  padding: 15px 0 0;
  width: 40px;
}

#nav-export img {
  width: 100%;
}

a,
a:hover,
a:visited,
a:active {
  color: #4c004c;
  font-weight: bold;
  text-decoration: none;
}

.popup-card {
  background-color: #fff;
  border-radius: 5px;
  bottom: 10px;
  box-shadow:
    0 4px 8px 0 rgb(0 0 0 / 20%),
    0 6px 20px 0 rgb(0 0 0 / 19%);
  padding: 25px 25px 10px;
  position: fixed;
  right: 10px;
  width: 20%;
  z-index: 1;
}

.popup-content {
  display: flex;
  flex-direction: column;
  font-size: 11pt;
}

.popup-content a {
  text-decoration: underline;
}

.popup-content h3 {
  margin-top: 0;
}

.close-popup {
  background: none;
  border: 0;
  font-size: 35px;
  font-weight: bold;
  line-height: 1;
  margin: 10px;
  position: absolute;
  right: 0;
  top: 0;
  width: 35px;
}

.accept-popup {
  background: #5c497e;
  border: 0;
  color: #fff;
  font-size: 16px;
  padding: 10px;
}

.popup-content label {
  align-self: center;
  margin: 5px;
}

.ui-dialog {
  background-color: #fff;
  box-shadow: 8px 8px 8px;
  box-shadow: 8px 8px 8px;
  box-shadow: 8px 8px 8px;
  box-shadow: 8px 8px 8px;
  box-shadow: 8px 8px 8px;
  color: #000;
  outline: solid 0 #000;
  overflow-x: hidden;
  overflow-y: auto;
}

.ui-widget-overlay {
  height: 100%;
  left: 0;
  position: fixed;
  top: 0;
  width: 100%;
}

.modal-header {
  align-items: center;
  color: #437699;
  display: flex;
  justify-content: space-evenly;
}

.modal-body p,
ol {
  font-size: 12pt;
  line-height: 1.3em;
  margin: 5pt 0;
  text-align: left;
}

.leaflet-container {
  height: 100%;
  width: 100%;
}

#zoom-button {
  background-color: #dedae5;
  border: solid 2px rgba(122, 106, 150, 0.7803);
  border-radius: 7px;
  color: rgba(122, 106, 150, 0.7803);
  margin-top: 10px;
}

#zoom-button:hover {
  background-color: rgba(122, 106, 150, 0.7803);
  color: #dedae5;
}

#map-sidebar {
  overflow-y: auto;
  padding: 0;
}

.accordian-collapsed {
  border-radius: 7px;
  margin-bottom: 10px;
}

.accordian-expanded {
  border-radius: 7px 7px 0 0;
}

.sidebar-day {
  cursor: pointer;
  font-family: "Work Sans", sans-serif;
  font-weight: bold;
  padding: 10px;
}

.sidebar-day #map-day-title {
  display: inline-block;
  height: 40px;
  position: relative;
  width: -ms-calc(100% - 30px);
  width: -o-calc(100% - 30px);
  width: calc(100% - 30px);
}

.sidebar-day #expand-icon {
  height: 30px;
  width: 30px;
}

.selected-day {
  background-color: #7a6a96;
  color: #dedae5;
}

.unselected-day {
  background-color: #dedae5;
  color: rgba(122, 106, 150, 0.7803);
}

#map-day-list {
  list-style: none;
  padding-left: 0;
}

#map-day-list .map-day-lec {
  font-family: "Work Sans", sans-serif;
  margin-top: -3px;
  padding-left: 10px;
  padding-right: 10px;
}

#map-day-list .unselected-day-lec {
  border: solid 3px #dedae5;
}

#map-day-list .selected-day-lec {
  border: solid 3px #7a6a96;
  position: relative;
}

.modal-class {
  background-color: #fff;
  border-radius: 10px;
  bottom: 150px;
  box-shadow: 0 0 30px;
  box-shadow: 0 0 30px;
  box-shadow: 0 0 30px;
  box-shadow: 0 0 30px;
  box-shadow: 0 0 30px;
  height: 550px;
  left: 200px;
  overflow-y: auto;
  position: absolute;
  right: 300px;
  top: 150px;
  width: 1000px;
}

.overlay {
  background-color: rgba(0, 0, 0, 150);
  bottom: 0;
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
  z-index: 2;
}

.ui-dialog-titlebar {
  border-bottom: solid 1px #000;
  color: #437699;
  cursor: move;
  font-size: 1em;
  height: 1.8em;
  line-height: 1.8em;
  padding-left: 25px;
}

.ui-dialog-titlebar-close {
  display: none;
}

.ui-width-overlay {
  height: 100%;
  left: 0;
  position: fixed;
  top: 0;
  width: 100%;
}

.ui-dialog tr {
  margin: 0 auto;
}

#course-video-div {
  height: 100%;
  margin: 5pt 0;
  width: 100%;
}

#course-video {
  height: 100%;
  width: 100%;
}

.modal-header {
  border-bottom: solid 1px #000;
  color: #437699;
  font-size: 1em;
  line-height: 1.8em;
  padding: 0;
  padding-left: 25px;
  text-align: left;
}

#post-image {
  border: solid 3px #000;
  border-radius: 5px;
  margin: 0 auto 10px;
  max-width: 100%;
}

#modal-buttons {
  float: right;
  margin: 20px auto;
}

#modal-buttons .btn {
  margin: 0 5px;
}

#react-graph {
  display: inline-block;
  height: 100%;
  margin: 0;
  overflow: hidden;
  position: absolute;
  text-align: center;
  width: 100%;
}

#react-graphRootSVG {
  height: 100%;
  shape-rendering: geometricPrecision;
  stroke: #000;
  stroke-linecap: square;
  stroke-miterlimit: 10;
  width: 100%;
}

.panning {
  cursor: grab;
}

.highlight-nodes {
  background-color: #808080;
}

.graph-control-button {
  background-color: #fff;
  border: 0;
  border-radius: 6px;
  color: #5c497e;
  cursor: pointer;
  display: inline-block;
  font-size: 18px;
  font-weight: bold;
  height: 30px;
  line-height: 20px;
  outline: none;
  padding: 5px;
  position: relative;
  text-align: center;
  width: 30px;
}

.graph-control-button:hover {
  background-color: #cac4d4;
  color: #fff;
}

.graph-control-button:active {
  background-color: #5c497e;
  color: #fff;
}

.graph-control-button:disabled {
  background-color: #dedede;
  color: #9c9c9c;
}

.graph-control-button img {
  height: 100%;
  width: 100%;
}

#reset-view-button {
  align-items: center;
  box-shadow: 1px 0 5px 0 #808080;
  display: flex;
  justify-content: center;
  padding: 4px;
}

#container {
  background: #f0f5f8;
  height: calc(100% - 50px);
  position: relative;
  width: 100%;
}

.sidebar {
  background-color: #fff;
  border: solid #d3d4d6;
  border-radius: 10px;
  border-width: 0 1px;
  box-shadow: 4px 4px 4px #808080;
  display: flex;
  flex-direction: column;
  position: absolute;
  right: $graph-overlay-side-margin;
  top: $graph-overlay-top-margin + 120px;
  width: 160px;
  z-index: 1;
}

.sidebar-dropdown {
  padding: 10px;
}

.expanded > .sidebar-button > #sidebar-icon {
  transform: rotate(180deg);
  transform: rotate(180deg);
  transform: rotate(180deg);
  transform: rotate(180deg);
  transform: rotate(180deg);
}

div.sidebar.collapsed {
  height: 40px;
}

.collapsed > .sidebar-dropdown {
  display: none;
}

.sidebar-button {
  background-color: #5c497e;
  border-radius: 10px;
  cursor: pointer;
  height: 15px;
  margin-left: 70px;
  margin-top: -5px;
  width: 30px;
}

.selected-courses {
  color: #946aa8;
  font-size: 14px;
  margin-bottom: 10px;
  margin-top: 10px;
}

.courses {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  max-height: 275px;
  max-width: 140px;
  overflow: auto;
}

.course-selection,
.course-selection:hover {
  cursor: pointer;
  font-weight: normal;
  text-decoration: underline;
  text-decoration-color: #4c004c;
}

.sidebar-button:hover {
  background-color: #675d79;
}

#sidebar-icon {
  height: 15px;
  width: 15px;
}

.fcecount {
  font-size: 16px;
  line-height: 40px;
  text-align: center;
}

.reset-selections {
  background-color: #f5f5f5;
  border: 0;
  border-radius: 15px;
  box-shadow: 3px 3px 3px #dadbdd;
  color: #946aa8;
  font-size: 12px;
  margin: 10px;
  outline: none;
  padding: 5px 10px;
  text-align: center;
}

.reset-selections:hover {
  background-color: #cfccd4;
}

.label-hidden {
  display: none;
}

.search-bar {
  margin: 0 auto;
  width: 100%;
}

.search-dropdown {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  list-style: none;
  margin-bottom: 0;
  max-height: 100px;
  max-width: 145px;
  overflow: auto;
  padding-left: 0;
  text-align: center;
}

.dropdown-item {
  font-size: 14px;
  width: 100%;
}

.dropdown-item:hover {
  cursor: pointer;
}

.spotlight {
  fill: #fff;
  stroke: none;
}

.active {
  background-color: #7a6a96;
}

.disabled {
  background-color: #dedede;
  pointer-events: none;
}

.graph-dropdown-item {
  cursor: pointer;
}

.graph-dropdown-item:hover {
  background-color: #dedede;
  color: #000;
}

.focus-menu-bar {
  align-items: flex-end;
  bottom: 0;
  display: flex;
  justify-content: center;
  margin-left: 25px;
  margin-right: 25px;
  position: fixed;
  width: calc(100% - 50px);
}

.focus-menu-toggle {
  background-color: #a273d3;
  border: 0;
  border-radius: 17px 17px 0 0;
  color: #fff;
  font-size: 20px;
  left: 25px;
  padding: 7px 15px 0;
  position: fixed;
  width: 150px;
}

.focus {
  align-items: center;
  background-color: #a172d2;
  border-top-left-radius: 17px;
  border-top-right-radius: 30px 53px;
  box-shadow: 0 12px 20px 6px rgb(0 0 0 / 50%);
  display: flex;
  float: right;
  height: 45px;
  justify-content: center;
  margin: 5px -7px 0;
  padding: 3px 7px 0;
  position: relative;
  width: 130px;
}

.focus button {
  background-color: transparent;
  border: 0;
  color: #fff;
  font-size: 13px;
  width: 114px;
}

.focus::after,
.active-focus::before,
.focus:hover::before {
  background-color: transparent;
  border-radius: 50%;
  border-style: solid;
  border-width: 13px;
  content: "";
  height: 70px;
  position: absolute;
  top: -5px;
  width: 40px;
}

.focus::after {
  border-color: transparent transparent transparent #a172d2;
  right: -32px;
  transform: rotate(315deg);
}

.active-focus,
.focus:hover {
  background-color: #bf7ce6;
  border-top-left-radius: 30px 53px;
  width: 153px;
  z-index: 1;
}

.active-focus::before,
.focus:hover::before {
  border-color: transparent #bf7ce6 transparent transparent;
  right: 145px;
  transform: rotate(-315deg);
}

.active-focus::after,
.focus:hover::after {
  border-color: transparent transparent transparent #bf7ce6;
}

.focus-info button {
  background-color: #fff;
  border: 0;
  border-radius: 100%;
  box-shadow: 1px 2px 6px 1px rgb(0 0 0 / 35%);
  color: #a273d3;
  font-family: serif;
  font-size: 19px;
  font-style: italic;
  font-weight: bold;
  height: 25px;
  margin-right: 3px;
  position: relative;
  width: 25px;
  z-index: 1;
}

.button-container {
  display: flex;
  margin-left: auto;
}

.info-modal-button {
  align-items: center;
  border-color: transparent;
  border-radius: 10px;
  box-shadow: 1px 0 5px 0 #808080;
  display: flex;
  height: 30px;
  justify-content: center;
  margin-bottom: 10px;
  margin-right: 20px;
  margin-top: 10px;
  width: 50px;
}

.info-modal-button:disabled {
  background-color: #a0aec0;
}

.flip {
  transform: rotate(180deg);
  transform: rotate(180deg);
  transform: rotate(180deg);
  transform: rotate(180deg);
  transform: rotate(180deg);
}

g text {
  -webkit-touch-callout: none;
  user-select: none;
  user-select: none;
  user-select: none;
  user-select: none;
  user-select: none;
  user-select: none;
  user-select: none;
  user-select: none;
  user-select: none;
}

g.node {
  cursor: pointer;
}

g.node text {
  font-size: 12pt;
  opacity: 0.4;
  stroke: none;
  text-anchor: middle;
}

g.node[data-active="active"] > rect {
  stroke-width: 3;
}

g.node[data-active="active"] > text {
  opacity: 1;
}

g.node[data-active="overridden"] > rect {
  stroke: #c01;
  stroke-width: 3;
  stroke-width: 2px;
}

g.node[data-active="overridden"] > text {
  opacity: 1;
}

g.node[data-active="inactive"] > rect {
  opacity: 0.4;
  stroke-dasharray: 8, 5;
  stroke-width: 2px;
}

g.node[data-active="takeable"] > rect {
  opacity: 0.69999;
}

g.node[data-active="takeable"] > text {
  opacity: 0.69999;
}

g.node[data-active="missing"] > rect,
g.node[data-active="missing"] > ellipse {
  stroke: #c01;
  stroke-width: 3;
  stroke-width: 2px;
}

g.node[data-active="missing"] > text {
  opacity: 1;
}

g.node[data-active="unselected"] > rect {
  opacity: 0.4;
  stroke-width: 3;
}

g.node > rect {
  stroke: #000;
}

g.hybrid {
  cursor: default;
}

g.hybrid > text {
  fill: #fff;
  font-size: 9pt;
  stroke: none;
  text-anchor: middle;
}

g.hybrid > rect {
  fill: #888;
  stroke: #000;
}

g.hybrid.inactive > rect {
  stroke-width: 0;
}

g.active > rect {
  stroke-width: 3;
}

g.active > text {
  opacity: 1;
}

g.overridden > rect {
  stroke: #c01;
  stroke-width: 3;
  stroke-width: 2px;
}

g.overridden > text {
  opacity: 1;
}

g.inactive > rect {
  opacity: 0.4;
  stroke-dasharray: 8, 5;
  stroke-width: 2px;
}

g.takeable > rect {
  opacity: 0.69999;
}

g.takeable > text {
  opacity: 0.69999;
}

g.missing > rect,
g.missing > ellipse {
  stroke: #c01;
  stroke-width: 3;
  stroke-width: 2px;
}

g.missing > text {
  opacity: 1;
}

g.bool {
  cursor: default;
}

g.bool[data-active="active"] > ellipse {
  fill: #fff;
  stroke: #000;
}

g.bool[data-active="overridden"] > ellipse {
  fill: #fff;
  stroke: #c01;
  stroke-width: 2px;
}

g.bool[data-active="inactive"] > ellipse {
  fill: #ccc;
  opacity: 0.4;
  stroke: #000;
  stroke-dasharray: 8, 5;
  stroke-width: 2px;
}

g.bool[data-active="takeable"] > ellipse {
  fill: #ccc;
  stroke: #000;
}

g.bool[data-active="missing"] > ellipse {
  fill: #fff;
  stroke: #c01;
  stroke-width: 2px;
}

g.active.bool > ellipse {
  fill: #fff;
  stroke: #000;
}

g.bool.overridden > ellipse {
  fill: #fff;
  stroke: #c01;
  stroke-width: 2px;
}

g.bool.inactive > ellipse {
  fill: #ccc;
  opacity: 0.4;
  stroke: #000;
  stroke-dasharray: 8, 5;
  stroke-width: 2px;
}

g.bool.takeable > ellipse {
  fill: #ccc;
  stroke: #000;
}

g.bool.missing > ellipse {
  fill: #fff;
  stroke: #c01;
  stroke-width: 2px;
}

g.bool > text {
  font-family: "Trebuchet MS", "Arial", sans-serif;
  font-size: 6pt;
  font-weight: bold;
  stroke: none;
  text-anchor: middle;
}

path {
  fill: none;
}

path[data-active="drawn"] {
  opacity: 0.4;
  stroke-width: 3;
}

path.takeable {
  stroke: #000;
  stroke-dasharray: 8, 5;
  stroke-width: 2px;
}

path.inactive {
  opacity: 0.4;
  stroke: #000;
  stroke-dasharray: 8, 5;
  stroke-width: 2px;
}

path.active {
  opacity: 1;
  stroke: #000;
  stroke-width: 2px;
}

path.missing {
  stroke: #c01;
  stroke-dasharray: 8, 5;
  stroke-width: 2px;
  stroke-width: 2px;
}

#resetButton {
  cursor: pointer;
  fill: #900;
  stroke: #404040;
  stroke-width: 3;
}

#resetButton > text {
  fill: #fff;
}

#svgTitle {
  fill: #072d68;
  font-family: "Bitter", serif;
  font-size: 2.5em;
  font-style: italic;
  font-weight: bold;
}

.region-label {
  font-size: 13pt;
}

.region {
  fill-opacity: 0.25;
}

.main {
  height: 84%;
  margin: 0;
}

#grid-body {
  min-height: 540px;
  padding: 0;
}

#search-layout {
  background-color: #7a6a96;
  margin: 0;
  overflow-x: hidden;
  overflow-y: hidden;
  padding: 0;
}

#filter-container {
  height: 10%;
  padding: 10px;
}

#filter-container > form {
  /* background    : url("/static/res/ico/search.png") no-repeat right center; */
  margin: 0.5em 0 0;
  padding-right: 40px;
}

#search-container {
  color: #fff;
  height: auto;
  margin: 0;
  max-height: 25%;
  overflow-y: auto;
  padding: 0;
  text-align: center;
  width: 100%;
}

#search-list {
  height: auto;
  margin: 0;
  padding: 0 5%;
  width: 100%;
}

#search-list > ul {
  margin: 0;
  padding: 0;
}

#search-list > ul > li {
  background-color: #9c91b0;
  display: block;
  margin: 0;
  padding: 0;
}

#search-list > ul > li:hover {
  cursor: pointer;
  font-weight: bold;
  text-decoration: underline;
}

#search-list > ul > li.starred-course {
  background-color: #261b2a;
}

.timetable-container {
  background-color: #fff;
  margin: 0;
  padding: 0;
}

.timetable {
  border-collapse: collapse;
  margin: 0;
  padding: 0;
  table-layout: fixed;
}

.timetable > caption {
  font-size: 1.6em;
  margin: 0.5em 0;
  text-align: center;
}

#timetable-F.timetable {
  border-right: solid 2px #000;
}

#timetable-F.timetable .timetable-time {
  border-bottom: solid 2px #db94b8;
  padding-right: 10px;
  text-align: right;
}

#timetable-S.timetable {
  border-left: solid 2px #000;
}

#timetable-S.timetable .timetable-time {
  border-bottom: solid 2px #db94b8;
  padding-left: 10px;
  text-align: left;
}

.timetable tbody td,
.timetable thead th {
  border-color: #db94b8;
  line-height: 0.9em;
  margin: 0;
  overflow: hidden;
  padding: 0;
  text-align: center;
  vertical-align: middle;
  width: 13.5%;
}

.timetable th {
  background-color: #db94b8;
  border-bottom: solid 2px #db94b8;
  font-size: 1.1em;
  font-weight: normal;
  height: 35px;
}

.timetable th.term-name {
  font-weight: bold;
  padding: 0;
  width: 10%;
}

.timetable th .map-icon {
  cursor: pointer;
  height: 24px;
  opacity: 0.5;
  width: 24px;
}

.timetable th .map-icon:hover {
  opacity: 1;
}

.timetable td {
  font-size: 0.9em;
  height: 21px;
}

.timetable td[rowspan*="1"] {
  font-size: 0;
}

.timetable .timetable-time {
  width: 12%;
}

.timetable .timetable-dummy-cell {
  border-style: none;
  width: 0%;
}

.timetable .timetable-half-cell {
  display: none;
}

.timetable .timetable-half-cell-display {
  display: table-cell;
}

.timetable .timetable-cell {
  border-bottom: solid 2px #db94b8;
  border-left-style: none;
  border-right-style: none;
  border-top: solid 2px #db94b8;
}

.timetable .timetable-cell-tophalf {
  border-bottom-style: none;
  border-left-style: none;
  border-right-style: none;
  border-top: solid 2px #db94b8;
}

.timetable .timetable-cell-bottomhalf {
  border-bottom: solid 2px #db94b8;
  border-left-style: none;
  border-right-style: none;
  border-top-style: none;
}

.timetable .timetable-edge {
  border-bottom-style: none;
  border-left: solid 2px #db94b8;
  border-right: solid 2px #db94b8;
  border-top: solid 2px #db94b8;
}

.timetable .timetable-middle {
  border-bottom-style: none;
  border-left: solid 2px #db94b8;
  border-right: solid 2px #db94b8;
  border-top-style: none;
}

.col-md-2 {
  width: 14%;
}

.col-md-8 {
  width: 72%;
}

.col-md-pull-2 {
  right: 14%;
}

.col-md-push-8 {
  left: 72%;
}

#course-select-wrapper {
  background-color: #7a6a96;
  color: #fff;
  height: auto;
  margin: 0;
  min-height: 70%;
  padding: 0;
}

#course-select {
  height: 100%;
  list-style-type: none;
  margin: 0;
  overflow: auto;
  padding: 18px 0 0 17px;
  text-align: center;
}

#course-select .ui-accordion-header {
  outline: solid 0 #fff;
}

#course-select > li {
  clear: both;
  width: 95%;
}

#course-select > li h3 {
  border-top: solid 1px #000;
  cursor: pointer;
  display: block;
  margin: 0;
  min-height: 40px;
  padding: 0.25em 0;
  width: 100%;
}

#clear-all > h3 {
  cursor: pointer;
  margin: 0;
  padding: 0.25em;
}

#clear-all > h3:hover {
  background-color: #261b2a;
}

#clear-all > h3.ui-accordion-header-active {
  background-color: #261b2a;
}

#course-select > li h3[data-satisfied*="false"] {
  background-color: #d77546;
}

#course-select > li h3[data-satisfied*="false"][taken*="true"] {
  background-color: #437699;
}

#course-select > li h3[data-satisfied*="true"][taken*="true"] {
  background-color: #5566f5;
}

#course-select .close-icon {
  cursor: pointer;
  height: 20px;
  opacity: 0.8;
  padding: 1px 0;
  width: 18px;
}

#course-select .close-icon:hover {
  opacity: 1;
}

#course-select .icon-div {
  float: left;
  min-height: 40px;
  width: 20px;
}

.sections {
  cursor: pointer;
}

.sections > ul {
  display: block;
  list-style-type: none;
  margin: 0;
  padding: 0;
}

.sections > ul.sectionList-F {
  border-right: solid 1px #000;
  float: left;
  width: 50%;
}

.sections > ul.sectionList-S {
  border-left: solid 1px #000;
  float: right;
  width: 50%;
}

.sections li {
  background-color: #336685;
  font-size: 0.8em;
}

.sections li:hover {
  background-color: #437699;
}

.sections li[clicked*="true"][data-satisfied*="false"] {
  background-color: #d77546;
}

.sections li[clicked*="true"][data-satisfied*="false"]:hover {
  background-color: #c36132;
}

.sections li[clicked*="true"][data-satisfied*="true"] {
  background-color: #437699;
}

.timetable td.hover-time {
  background-color: #5566f5;
}

.timetable td[hover*="good"] {
  background-color: #437699;
}

.timetable td[hover*="remove"] {
  opacity: 0.5;
  transition: all 0.5s ease-in-out 0s;
  transition: all 0.5s ease-in-out 0s;
  transition: all 0.5s ease-in-out 0s;
  transition: all 0.5s ease-in-out 0s;
  transition: all 0.5s ease-in-out 0s;
}

.timetable td[data-satisfied*="false"] {
  background-color: #d77546;
}

.timetable td[data-satisfied*="false"]:hover {
  background-color: #c36132;
}

.timetable td[data-in-conflict*="true"] {
  background-color: #c92343;
}

.timetable td[data-in-conflict*="true"]:hover {
  background-color: #b91333;
}

.timetable td[data-in-conflict*="false"][data-satisfied*="true"] {
  background-color: #437699;
}

.timetable td[data-in-conflict*="false"][data-satisfied*="true"]:empty {
  background-color: #fff;
}

.timetable td[data-satisfied*="true"][hover*="conflict"],
.timetable td[data-satisfied*="false"][hover*="conflict"] {
  background-color: #c92343;
}

#modal-content-container > a {
  margin-left: 10px;
  outline: none;
  text-decoration: none;
}

#modal-content-container > a:hover {
  text-decoration: underline;
}

#colour-table {
  height: 40px;
  width: 200px;
}

#colour-table tr:nth-child(1) td:nth-child(1) {
  background: #ff7878;
}

#colour-table tr:nth-child(1) td:nth-child(2) {
  background: #ed9;
}

#colour-table tr:nth-child(1) td:nth-child(3) {
  background: #9bd1fa;
}

#colour-table tr:nth-child(1) td:nth-child(4) {
  background: #ffd1dc;
}

#colour-table tr:nth-child(1) td:nth-child(5) {
  background: #fff;
}

#colour-table tr:nth-child(2) td:nth-child(1) {
  background: #ffc48c;
}

#colour-table tr:nth-child(2) td:nth-child(2) {
  background: #bdecb6;
}

#colour-table tr:nth-child(2) td:nth-child(3) {
  background: #e3aad6;
}

#colour-table tr:nth-child(2) td:nth-child(4) {
  background: #ad876e;
}

#colour-table tr:nth-child(2) td:nth-child(5) {
  background: #a2a9af;
}

#main {
  border: solid 2px #000;
  border-radius: 8px;
  float: right;
  height: 85%;
  position: relative;
  width: 85%;
}

#about-div {
  font-size: 1.19999em;
  margin: 0 0 0 10px;
}

#background {
  /* background-image : url(/static/res/backgrounds/draw-background.png); */
  background-size: 8px;
  height: 100%;
  opacity: 0.29999;
  width: 100%;
}

#mySVG {
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
}

#side-panel-wrap {
  background-color: #008080;
  border: solid 2px #000;
  border-radius: 8px;
  float: left;
  height: 85%;
  overflow-y: auto;
  padding: 5px 0;
  width: 15%;
}

.mode {
  border: solid 2px #008080;
  border-radius: 8px;
  font-size: 0.75em;
  margin: 0 0 0 5px;
  padding: 0 0 0 5px;
  transition: all 0.2s;
  transition: all 0.2s;
  transition: all 0.2s;
  transition: all 0.2s;
  transition: all 0.2s;
  width: 93%;
}

.mode:hover {
  background-color: #28b0a2 !important;
  color: #dcdcdc !important;
  cursor: pointer;
}

.clicked.mode {
  background-color: #28b0a2 !important;
}

.clicked {
  border: solid 2px #000;
  color: #dcdcdc !important;
}

.button {
  border: solid 2px #008080;
  border: solid 2px #000;
  border-radius: 8px;
  font-size: 0.75em;
  margin: 5px;
  padding: 0;
  text-align: center;
  transition: all 0.2s;
  transition: all 0.2s;
  transition: all 0.2s;
  transition: all 0.2s;
  transition: all 0.2s;
  width: 40%;
}

.button:hover {
  background-color: #000 !important;
  color: #dcdcdc !important;
  cursor: pointer;
}

input {
  border: solid 2px #dcdcdc;
  border-radius: 8px;
  font-size: 16px;
  margin: 5px 0 5px 5px;
  padding: 0;
}

input:focus {
  box-shadow: 0 0 3px 1px #ffd700;
}

#add-text {
  display: inline;
  margin: 5px;
  padding: 2px 5px;
  width: 45%;
}

.mylabel {
  dominant-baseline: central;
  stroke: none;
  text-align: center;
  text-anchor: middle;
  -webkit-touch-callout: none;
  user-select: none;
  user-select: none;
  user-select: none;
  user-select: none;
  user-select: none;
  user-select: none;
  user-select: none;
  user-select: none;
  user-select: none;
}

.elbow {
  opacity: 0;
}

.elbow:hover {
  cursor: pointer;
  opacity: 1;
}

.rElbow {
  opacity: 0;
}

.rElbow:hover {
  cursor: pointer;
  opacity: 1;
}

#finish-region {
  background-color: #dcdcdc;
  border: solid 2px #008080;
  margin: 5px;
  padding: 0;
  width: 40%;
}

body {
  color: #222;
  font-weight: normal;
}

#posts {
  background-color: #fff;
  border-radius: 4px;
  display: block;
  font-family: "Work Sans", sans-serif;
  font-size: 15px;
  margin: 15px 0 25px;
  overflow: hidden;
}

#posts ul {
  margin: 0;
  width: 100%;
}

#posts li {
  display: inline-block;
  list-style-type: none;
  transition: all 0.2s;
  transition: all 0.2s;
  transition: all 0.2s;
  transition: all 0.2s;
  transition: all 0.2s;
  width: 32%;
}

#posts li:hover {
  background-color: #eee !important;
}

#posts li a {
  color: #000;
  display: inline-block;
  line-height: 50px;
  padding-left: 24px;
  text-decoration: none;
  width: 70%;
}

#posts ul .nav_selected {
  border: solid 1.5px #dcdcdc;
  border-bottom: solid 1.5px #fff;
}

#posts ul .nav_not_selected {
  border-bottom: solid 1.5px #dcdcdc;
}

#posts ul .credits_completed {
  color: #519a73;
  font-size: 15px;
}

#posts ul .credits_not_completed {
  color: #c91f37;
  font-size: 15px;
}

.year_name {
  border-bottom: solid 2px #815463;
  font-family: "Work Sans", sans-serif;
  font-size: 25px;
  font-style: normal;
}

.year_course_list {
  font-family: "Work Sans", sans-serif;
  font-size: 18px;
  font-style: normal;
  list-style-type: none;
  margin-top: 20px;
  padding-left: 5px;
}

.year_course_list .code {
  padding: 0;
  text-indent: -20px;
}

input {
  font-size: 14px;
}

#button_wrapper {
  height: 50px;
  padding-left: 20px;
  text-align: center;
}

#update {
  cursor: pointer;
  font-size: 14px;
  height: 40px;
  opacity: 0.69999;
}

#update:hover {
  opacity: 1;
}

.code::before {
  color: #fff;
  content: "\2713";
  padding-right: 5px;
}

.selected .code {
  color: #696;
}

.selected .code::before {
  color: #696;
  content: "\2713";
}

.selected .full_name {
  background-color: #aae4a4;
}

div .code {
  list-style-type: none;
  padding-left: 20px;
  transition: all 0.2s;
  transition: all 0.2s;
  transition: all 0.2s;
  transition: all 0.2s;
  transition: all 0.2s;
  width: 99%;
}

div .code .courseName {
  cursor: pointer;
}

div .code .courseName:hover {
  font-weight: bold;
}

i {
  color: #f00;
}

#post_specialist,
#post_major,
#post_minor {
  font-family: "Work Sans", sans-serif;
  font-size: 18px;
  font-weight: 300;
  height: 70%;
  padding: 30px 50px;
  position: absolute;
  width: 97%;
}

#spec_creds,
#maj_creds,
#min_creds {
  display: inline-block;
  margin-left: 0;
}

.more-info {
  background-color: #bababa;
  border: solid 2px #949494;
  border-radius: 4px;
  box-shadow: 0 2px 2px -1px rgba(0, 0, 0, 0.055);
  cursor: pointer;
  display: none;
  line-height: 40px;
  list-style-type: none;
  margin: 0;
  margin-right: 10px;
  text-align: center;
  transition: all 0.2s;
  transition: all 0.2s;
  transition: all 0.2s;
  transition: all 0.2s;
  transition: all 0.2s;
}

.info_opened > div {
  display: block;
}

.full_name {
  margin: 0;
  text-align: center;
}

input {
  height: 40px;
  text-align: center;
}

input[type="checkbox"] {
  height: 13px;
}

#notes {
  clear: left;
  float: center;
  font-weight: bold;
  padding-top: 20px;
}

#notes > ul {
  list-style-type: none;
}

.valid_extra_course {
  color: #008000;
}

.not_valid_extra_course {
  color: #f00;
}

.post_selected {
  display: block;
}

.post_not_selected {
  display: none;
}

div[id*="_category_"] .code::after {
  content: "\2B9F";
  cursor: pointer;
  padding-left: 10px;
}

div[id*="_inq"] .code::after {
  content: "\2B9F";
  cursor: pointer;
  padding-left: 10px;
}

#aboutDiv {
  margin: 0 auto;
  max-width: 1000px;
  padding: 0 1em;
  text-align: justify;
}

#aboutDiv > h1,
#aboutDiv > h2,
#aboutDiv > h3 {
  color: #437699;
}

#privacyDiv {
  margin: 0 auto;
  max-width: 1000px;
  padding: 0 1em;
  text-align: justify;
}

#privacyDiv > h1,
#privacyDiv > h2,
#privacyDiv > h3 {
  color: #437699;
}

#contentDiv {
  margin: 0 25px;
}

#picDiv {
  margin: 0 25px;
}

#links {
  list-style-type: none;
}

#timetableSearch {
  text-align: center;
}

#filter {
  border: solid 1px #000;
  font-family: "Trebuchet MS", "Arial", sans-serif;
  margin-left: 10px;
  text-align: center;
  width: 200px;
}

#deptSelect {
  margin-left: 10px;
  text-align: center;
}

#timetableContainer {
  height: 100%;
  margin-left: auto;
  margin-right: auto;
  margin-top: 1em;
  width: 1016px;
}

#timetableMain {
  background-color: #949494;
  border: solid 2px #000;
  border-spacing: 0;
  table-layout: fixed;
}

#timetableMain td,
#timetableMain th {
  border-color: #000;
  border-spacing: 0;
  border-style: solid;
  border-width: 2px 2px 1px;
  margin: 0;
  padding: 0;
  vertical-align: top;
}

#timetableMain td.sessionHeader,
#timetableMain th.sessionHeader {
  background-color: #a5a6f5;
  font-size: 1.1em;
  font-weight: bold;
  text-align: center;
}

#timetableMain td.timetableSection,
#timetableMain th.timetableSection {
  background-color: #ddbdbd;
  text-align: center;
  width: 75px;
}

#timetableMain td.timetableTime,
#timetableMain th.timetableTime {
  background-color: #b8e7f9;
  padding-left: 5px;
  padding-right: 5px;
  width: 110px;
}

#timetableMain td.timetableInstructor,
#timetableMain th.timetableInstructor {
  background-color: #cd96cd;
  padding-left: 5px;
  width: 130px;
}

#timetableMain td.timetableCap,
#timetableMain th.timetableCap {
  background-color: #aae4a4;
  text-align: center;
  width: 90px;
}

#timetableMain td.timetableWait,
#timetableMain th.timetableWait {
  background-color: #ecbdd2;
  text-align: center;
  width: 40px;
}

#timetableMain .courseTable {
  border-spacing: 0;
  min-height: 23px;
  table-layout: fixed;
}

#timetableMain .courseTable tr,
#timetableMain .courseTable td,
#timetableMain .courseTable th {
  border-style: none;
}

#timetableMain .timetableCourseName {
  background-color: #a5a6f5;
  font-size: 1.05em;
  min-width: 90px;
  text-align: center;
}

#timetableMain .FOffering,
#timetableMain .SOffering,
#timetableMain .YOffering {
  width: 460px;
}

#timetableMain .FOffering > table,
#timetableMain .SOffering > table,
#timetableMain .YOffering > table {
  border-style: none;
  margin: 0;
  padding: 0;
  width: 460px;
}

#timetableMain .YOffering .courseTable {
  margin-left: auto;
  margin-right: auto;
}

#generateRoot {
  height: 100%;
  position: relative;
}

#generateRoot #react-graph {
  position: static;
}

#generateDiv {
  background-color: #5c497e;
  color: #d9e4ee;
  height: 100%;
  width: 500px;
}

#generateDiv ul {
  list-style-type: none;
  padding-left: 5px;
}

#generateDiv ul li {
  color: #d9e4ee;
  font-size: 12pt;
  padding-left: 23px;
  padding-right: 10px;
}

#generateDiv input {
  background-color: #d9e4ee;
  color: #5c497e;
  margin: 0;
}

#generateDiv input[type="text"] {
  width: 100%;
}

#generateDiv label {
  font-weight: normal;
}

#header-title {
  font-size: 1.19999em;
}

main-filter {
  margin-top: 30px;
  text-align: center;
}

main-filter input {
  border: solid 5px #5c497e;
}

#filter-title {
  color: #d9e4ee;
  font-size: 20px;
}

#submit {
  color: #5c497e;
}

.spinner {
  animation-duration: 2500ms;
  animation-iteration-count: infinite;
  animation-name: spin;
  animation-timing-function: linear;
  position: relative;
}

#compass-small {
  left: -178px;
  top: -9px;
}

#compass {
  left: -388px;
  top: -33px;
}

.tooltip-group-display {
  cursor: pointer;
  opacity: 1;
  transition: opacity 0.4s linear;
}

.tooltip-group-hidden {
  cursor: pointer;
  opacity: 0;
  transition: opacity 0.4s linear;
}

.hidden {
  display: none;
}

.graph-dropdown-display {
  background-color: #5c497e;
  color: #fff;
  column-count: 2;
  display: inline-block;
  font-size: 15px;
  list-style: none;
  padding: 8px;
  position: absolute;
  text-align: left;
}

.show-dropdown-arrow > a::after {
  content: "▼";
  font-size: 15px;
  padding-left: 5px;
}

.button-group {
  border-radius: 6px;
  box-shadow: 1px 0 5px 0 #808080;
  display: flex;
  flex-direction: column;
  margin-top: 10px;
}

.graph-button-group {
  display: flex;
  flex-direction: column;
  position: absolute;
  right: $graph-overlay-side-margin;
  top: $graph-overlay-top-margin;
}

.selected-page {
  border-bottom: 4px solid #deddbb;
}

#nav-links > .selected-page > a {
  color: #deddbb;
}

#nav-generate {
  white-space: nowrap;
}
